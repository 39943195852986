
    .about {
  
        position:static;
        height: 100vh;
        background-size:cover;
        background-position:center;
        background-image:url('../Assets/d1.jpg');
        display: flex;
      align-items: center;
      justify-content: center;
     
  
   
      }


      .bg1 {
        position:static;
      height: 100vh;
        background-size:cover;
        background-position:center;
    
        align-items: center;
        justify-content: center;
        display: flex;
      
      }
    
