.img1{
    position: relative;
}

.img1_img{
    display: block;
    width:100%;
    height:250px;
}

.text{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1f212290;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 1s;
    letter-spacing: 2px;
    font-size: 12px;
   
}

.text:hover{
    opacity: 1;
}

