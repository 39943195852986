.footer{
    color: white;
    padding:7vh;
    background-color: #1f2122;
    padding-top: 12vh;
    display: flex;

    flex-direction: column;
    align-items: center;

    }
    
    .mobile{
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: black;
        color: white;
        padding-top: 8vh;
        padding-left: 2vh;
        padding-right: 2vh;
        padding-bottom: 30px;
    }



    .menu{
        font-size: 12px;
        cursor: pointer;
        text-decoration: none;
        color:grey;
        font-family: 'Raleway';
        font-weight: bold;
        letter-spacing: 1px;
    }

    .menu.foot{
            margin-top: 20px;
            font-size: 16px;
            text-align: center;
    }

    .menu:hover{
        
          color: white;
          transition: 1000ms;
        
        }

    .icon{
        color: #c2b19c;
    
        height: 20px;
        width: 20px;
    }

 
    
    .link{
  
        padding-bottom: 8px;
        font-size: 15px;
        color: white;
        transition: 100ms;
    }
    
    .link:hover{
    transition: 100ms;
      font-size: 20px;
      color: white;
    
    }
    a:hover{
        text-decoration: none;
    }
    

