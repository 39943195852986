.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.bg {
  position:static;
height: 100vh;
  background-size:cover;
  background-position:center;
  background-image:url('./Assets/bg.jpg');

}

.mob{
  position:static;
  height: 60vh;
    background-size:cover;
    background-position:center;
    background-image:url('./Assets/bg.jpg');
  
}


.container {
  display: flex;
  overflow-x: auto;
  padding-bottom: 25px;
  margin-left: 50px;
  margin-top: 50px;
  width:100vw;

}
.container img {
  margin-right: 10px;
  height: 60vh;
  width: 60vh;
}
.container::-webkit-scrollbar {
  width: 2em;
}

.container::-webkit-scrollbar-track {
  background:#d7d5d3;
}

.container::-webkit-scrollbar-thumb {
  background:black;
  width: 10;
  border-radius: 100vw;
  border: 0.5em solid #d7d5d3;
}

.App-link {
  color: #61dafb;
}


.bright {
  background-color: white;
  display: inline-block;

}
.bright img:hover {
filter: brightness(50%); 
transition: 1s;
cursor: pointer;
}

.scroll-container {
 background-color: #f2f2f1;
  overflow: hidden;
  padding:60px 0;
  white-space: nowrap;
}

.scroll-text {
  /* animation properties */
  display: inline-block;

  animation:my-animation 20s linear infinite ;
}

.scroll-text  img{
  height: 50px;
  margin:0 100px
}


@keyframes my-animation {
  from {
    transform: translateX(0%);
}
to {
    transform: translateX(-100%);
}
}



.gallery{
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  margin-top: 50px;
}

.gallery .pics{
 -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 50px;
}

.gallery .pics:hover{
  filter: opacity(.8);

}

.photo{

  margin-bottom:10;
  transition: 1s;
  overflow: hidden;
}

.photo:hover{
  margin-bottom: -40px;

}

.desc{
  opacity: 0;
  transition: opacity .2s, visibility .2s;
  position: relative;
  font-size: 16px;
}



.pro:hover .desc {
  opacity: 1;
  
}


@media (max-width: 991px) {
.gallery {
 -webkit-column-count: 2;
 -moz-column-count: 2;
 column-count: 2;
}
}
@media (max-width: 480px) {
.gallery {
 -webkit-column-count: 1;
 -moz-column-count: 1;
 column-count: 1;
 -webkit-column-width: 100%;
 -moz-column-width: 100%;
 column-width: 100%;
}
}




.testimonial{
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 50%;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
}